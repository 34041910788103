













































import { Component } from 'vue-property-decorator'
import AbstractField from '@/shared/classes/components/form/fields/abstract-field'
import FormField from '@/shared/components/form/FormField.vue'
import __ from '@/helpers/__'
import { Container, Draggable } from 'vue-dndrop'
import _ from 'lodash'

interface DropResult {
  removedIndex: number
  addedIndex: number
  payload: any
}

@Component({
  components: {
    FormField,
    Container,
    Draggable
  },
  methods: { __ }
})
export default class ArrayFieldDraggable extends AbstractField {
  created(): void {
    if (!this.field.initRows && this.field.children.length > 0) return

    const keys = Object.keys(this.field.fieldTemplates)

    this.value &&
      this.value.forEach((element: any) => {
        const key: string = keys.length > 1 ? element.type : keys[0]
        this.addField(this.field.fieldTemplates[key], key)
      })

    if (this.field.createNewRow) {
      this.addField(this.field.fieldTemplates[keys[0]], keys[0])
    }
  }

  addField(fields: any, key: string) {
    if (!this.value) this.value = []
    this.field.addChildren(fields, key, this.form)
  }

  handleDrop(dropResult: DropResult) {
    const elementArray: any = _.cloneDeep(this.field.children)
    elementArray.splice(dropResult.removedIndex, 1)
    elementArray.splice(dropResult.addedIndex, 0, this.field.children[dropResult.removedIndex])
    this.field.setChildren(elementArray)
  }

  get total(): number {
    let sum: number = 0
    Object.keys(this.field.groupLocalData).forEach((key: any) => {
      if (this.field.groupLocalData[key].total) sum += this.field.groupLocalData[key].total
    })
    return sum
  }

  get totalWithVat(): number {
    let sum: number = 0
    Object.keys(this.field.groupLocalData).forEach((key: any) => {
      if (this.field.groupLocalData[key].totalWithVat) sum += this.field.groupLocalData[key].totalWithVat
    })
    return sum
  }

  get totalVat(): number {
    return this.totalWithVat - this.total
  }
}
